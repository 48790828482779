.firstdiv,
.imagecard,
.seconddiv {
  display: flex;
  background-color: #fff;
}

.lending-btn:hover,
.submit_btn:hover {
  background: linear-gradient(to bottom, #7a1353 0, #cc2e8f 100%);
}

.btn_padding{
  padding: 18px !important;
}

:root {
  --thm-font2: "Bubblegum Sans", sans-serif;
  --thm-white: #ffffff;
  --thm-font: "Open Sans", sans-serif;
  --thm-f3: #f3f3f3;
}

html {
  overflow-x: hidden;
  height: 100%;
}
ul.dropdown-menu.show li{
  border-bottom: none !important;
}

section#homepage-main-new {
  background-image: url(../assets/img/newhomepage/banner_2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f8ff;
  padding-top: 200px;
  height: 700px;
}
.video_border{
    
    padding-left: 8%;
    padding-right: 8%;
}
.new_border{
  border: 4px solid #D13494;
}
.banner-text2 {
  font-size: 18px;
  line-height: 30px;
  margin: 10px 0;
  color: #fff;
  font-family: var(--thm-font);
  font-weight: 600;
  letter-spacing: 0 !important;
  text-align: left;
}
.video-container {
  max-width: 66%; /* Set maximum width for the video container */
  margin: auto; /* Center align the video */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow around the video */
  border-radius: 8px; /* Optional: rounded corners for the video and poster */
  overflow: hidden; /* Ensures the rounded corners affect the video */
}

.responsive-video {
  width: 100%; /* Make the video responsive */
  height: auto; /* Maintain aspect ratio */
}

.video-caption {
  text-align: center; /* Center-align the caption */
  margin-top: 10px; /* Space between video and caption */
  font-size: 16px; /* Caption font size */
  color: #666; /* Caption font color */
}

.largefont,
.lessontext,
.mathsfont,
.subject-title {
  font-family: "Times New Roman", Times, serif;
}

.banner-text1 {
  font-weight: 400;
  font-size: 55px;
  color: #fff;
  text-align: left;
  line-height: 1.5 !important;
}

.largefont,
.learning-form h1,
.learning-form p,
.lending-btn,
.subheading,
.subject-title {
  text-align: center;
}

.font {
  line-height: 30px !important;
  font-size: 20px !important;
}

.lending-btn,
.lending-btn:hover {
  font-size: 19px;
  color: #fff;
}
.lending-btn.bookdemo{
  margin-top: 12px;
}
.faqAccordion .card {
  border: none; /* Removes default borders */
  margin-bottom: 1rem; /* Spacing between each FAQ item */
}

.faqAccordion .card-header {
  background-color: #f8f9fa; /* Light grey background */
  border-bottom: 1px solid #eaecef; /* Adds a subtle line between the header and content */
}

.faqAccordion .btn-link {
  text-decoration: none; /* Removes underline from links */
  color: #333; /* Dark color for text */
  font-weight: bold; /* Makes the FAQ questions bold */
  font-size: 1.1rem; /* Slightly larger text for readability */
  padding: 1rem; /* Padding for larger click/tap area */
  width: 100%; /* Ensures the clickable area spans the full width of the accordion */
  text-align: left; /* Aligns text to the left */
}

.faqAccordion .btn-link:hover,
.faqAccordion .btn-link:focus {
  color: #0056b3; /* Changes text color on hover/focus for better user feedback */
}

.faqAccordion .card-body {
  padding: 20px; /* Adequate padding for content */
  background-color: #ffffff; /* White background for the content */
  font-size: 0.9rem; /* Slightly smaller font for answers */
  line-height: 1.5; /* Improves readability */
}
.rotate-icon {
  transform: rotate(180deg); /* Rotate arrow when active */
  transition: transform 0.3s ease-in-out; /* Smooth rotation animation */
}

.btn-link {
  display: flex;
  justify-content: space-between; /* Aligns the text and arrow icon */
  align-items: center; /* Centers items vertically */
}

.lending-btn {
  float: left;
  border: none;
  border-radius: 0;
  padding: 7px;
  background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
  font-weight: 600;
  width: 225px;
  cursor: pointer;
  text-decoration: none;
}
.card-header .btn-link {
  display: flex;
  justify-content: space-between; /* Ensures the question text and arrow are on opposite ends */
  align-items: center; /* Vertically centers content within the button */
  width: 100%; /* Full width to stretch across the card header */
  text-decoration: none; /* Removes underline from the button */
  font-weight: bold;
  background: none; /* Removes any background styling */
  border: none; /* Removes any border styling */
  padding: 0.75rem 1.25rem; /* Adequate padding for clickable area */
}

.learning-form .slider-form-outer .btnRegister {
  float: none;
  margin-top: 15px !important;
  border: none;
  border-radius: 0;
  padding: 12px;
  background: #000;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  display: block;
}


.learning-form h2 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 2px;
  text-align: center;
}

.learning-form p {
  font-size: 18px;
  color: #fff;
  padding-bottom: 19px;
}

.learning-form {
  background: linear-gradient(274deg, #ffc107, #f026a1);
  padding: 30px;
  margin: 0 0 50px;
  border-radius: 20px;
}
.learning-form .form-control {
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  border: 2px solid #ffffff; /* White border to blend with the gradient */
  border-radius: 4px; /* Consistently rounded edges */
  padding: 12px 20px; /* Adequate padding for text */
  margin-bottom: 15px; /* Space between form elements */
  color: #333; /* Dark color for text */
  font-size: 14px; /* Sufficient size for readability */
}
/* Ensures that padding and borders are included in the width and height calculations */
.learning-form .form-control, .learning-form textarea.form-control {
  box-sizing: border-box; /* Critical for managing layout */
  width: 100%; /* Ensures it takes up only the allotted space */
  padding: 12px 20px; /* Adjust padding as needed, keeping within bounds */
  margin-bottom: 5px; /* Space below each input, including textarea */
  border-radius: 4px; /* Consistent rounded corners */
  border: 2px solid #ffffff; /* Stylish border, make sure it blends well */
  height: 90px;
}

/* Additional focus and hover styling for better user interaction feedback */
.learning-form .form-control:hover, .learning-form .form-control:focus,
.learning-form textarea.form-control:hover, .learning-form textarea.form-control:focus {
  border-color: #f026a1; /* Pink color to indicate focus or hover */
  background-color: #ffffff; /* Ensures solid background on interaction */
  box-shadow: 0 0 8px rgba(240, 38, 161, 0.5); /* Subtle glow effect */
}

.freedomestyle{
  top: 150px;
  width: 418px;
  position: absolute;
  z-index: 1;
  height: 638px;
}

.learning-form .form-control {
  display: block;
  width: 100%;
  padding: 0 19px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  height: 45px;
  border: 1px solid #a62274 !important;
  border-radius: 3px;
}

.largefont {
  font-size: 45px !important;
  font-weight: 700 !important;
}

.subheading {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px !important;
  color: #000;
}

.mathsfont,
.subject-title {
  font-weight: 700 !important;
}

.cardnew {
  width: 282px;
  margin: 10px;
  height: 445px;
  background-color: #000 !important;
  padding: 16px;
  border-radius: 28px !important;
  border: 2px solid #a62274;
}

.mobile {
  display: flex;
  justify-content: space-evenly;
}

.imagecard {
  padding: 12px;
  height: 170px;
  border-radius: 25px;
  margin: 10px;
  align-items: center;
  justify-content: space-evenly;
}

.firstdiv,
.seconddiv {
  height: 60px;
}

.subject-title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 4px;
}

.fontsize,
.mathsfont {
  font-size: 60px !important;
}

.bookdemo,
.center {
  margin-left: auto;
  margin-right: auto;
}

.card-textnew {
  margin-top: 11px;
  color: #fff;
  margin-bottom: 30px !important;
  text-align: justify;
}

.firstdiv {
  box-shadow: 3px 4px 5px grey;
  border: 1px solid #000;
  width: 90px;
  border-radius: 23px;
  justify-content: space-evenly;
  align-items: center;
  padding: 21px;
}

.seconddiv {
  box-shadow: 3px 4px 5px gray;
  border: 1px solid #000;
  width: 300px;
  border-radius: 22px;
  margin-left: 30px;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 375px) {
  .firstdiv,
  .seconddiv {
    height: 70px !important;
  }

  .seconddiv {
    width: 220px !important;
  }

  .firstdiv {
    width: 80px !important;
  }

  .deliver {
    display: contents;
  }

  .card2 {
    margin: 20px;
    width: 340px;
  }
  .margintopphone{
    margin-top: 16px;
  }
  .faq_question{
    margin-left: 0px !important;
  }
}

.bg_imagesec6 {
  background-image: url(../assets/img/newhomepage/section6_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-bottom: 100px;
}
.bckgrngimg {
  background-image: url(../assets/img/Group42.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: #fff; */
  padding-bottom: 100px;
}

img {
  max-width: 100%;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1210px;
  }
}

.colorBlack {
  color: #000 !important;
}

.mathsfont {
  margin-left: 25px;
  color: #fff;
  padding-top: 50px;
}

.lessontext,
.ml-auto {
  margin-left: auto;
}

.imagecard3 {
  height: auto;
  width: 460px;
}

.lessontext {
  font-weight: 700;
  color: #fff;
  font-size: 60px;
  margin-top: 70px;
}

.subheadingmaths {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 30px;
  text-align: end;
}

.terms p,
.textjustify {
  text-align: justify !important;
}

.test1 {
  margin-right: 10px;
}

.testcard {
  display: flex;
  border: 1px solid #f24f25;
  border-radius: 40px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-bottom: 45px;
  height: 448px;
}

.colorOrange {
  color: #f04f25 !important;
}

.one-to-one {
  color: #625797 !important;
}

.pink {
  color: #e01271 !important;
}

.center-item {
  display: flex;
  justify-content: center;
}

.border-radius {
  border-radius: 65px;
}

.img {
  width: 200px;
}
.review{
  float: right;
  margin-right: 5px;
}
.review img{
  padding-left: 2px;
  height: 40px;
  width: 50px;
  padding-right: 2px;
}
.testcard p{
  font-size: 15px;
}

.submit_btn,
.submit_btn:hover {
  float: right;
  color: #fff;
  width: 105px;
  height: 45px;
}

.textjustify {
  font-size: 15px;
}

.modal-footer {
  border-top: none !important;
}

.submit_btn {
  background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
}

#reload_href {
  background: none !important;
}

#homepage-main-new2 {
  background-image: url(../assets/img/newhomepage/banner_4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f8ff;
  padding-top: 200px;
  height: 700px;
}

#homepage-main-new3 {
  background-image: url(../assets/img/newhomepage/banner_2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f8ff;
  padding-top: 200px;
  height: 700px;
}

#homepage-main-new4 {
  background-image: url(../assets/img/newhomepage/banner_4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f8ff;
  padding-top: 200px;
  height: 700px;
}

.banner_margin {
  /* margin-top: 20% !important; */
}

.banner-text1 {
  font-size: 90px !important;
}

.deliver_class {
  border: 1px solid #000;
  border-radius: 25px;
  padding: 35px 27px 25px 30px;
  height: 260px;
}

.sec_2_text {
  text-align: left !important;
  font-size: 25px;
  color: #000;
  font-weight: 700;
  line-height: 30px !important;
  margin-top: 15px;
}

.faq_sec {
  border: 4px solid #D13494;
  border-radius: 15px;
}

.faq_question {
  font-weight: 900;
  border: 1.5px solid #000;
  border-radius: 19px;
  padding: 15px 10px 20px 29px;
  margin-left: 30px;
  align-items: center;
  color: rgb(145, 27, 100);
  cursor: pointer;
}

.faq_anss {
  font-weight: 900;
  border-radius: 19px;
  padding: 15px 29px 15px 29px;
  margin-left: 30px;
  align-items: center;
  background: linear-gradient(180deg, #e23ca2 0%, #911b64 100%);
  color: white;
  height: 100%;
  text-align: justify;
}

.faq_question .active {
  background-color: #000;
  color: #f1f8ff;
}

.focustab {
  background-color: #000;
  color: #f1f8ff;
  cursor: pointer;
}

.pragraftab {
  color: white;
  width: 100%;
  font-size: 22px;
  text-align: justify;
}

.faqbutn {
  width: 161px;
  height: 36px;
  flex-shrink: 0;
  padding-top: 1%;
  border-radius: 30px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.5);
}

.FAQS {
  color: #911b64;
  font-family: "Plus Jakarta Sans";
  font-size: 63px;
  font-style: normal;
  font-weight: 800;
  line-height: 80px; /* 126.984% */
  letter-spacing: -2px;
}

.howdowework_font {
  color: #fff;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reviwesfont1 {
  color: #fff;
  font-family: "Inter";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  margin-left: 23%;
}
.reviwesfont2 {
  color: #fff;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin-left: 22%;
}

.reviwesline {
  stroke-width: 1px;
  stroke: #fff;
}
.reviwesfont {
  color: #fff;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.slider-form-outer input.form-control,
.slider-form-outer select#you_are {
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.robot_input {
  background-color: #fff;
  padding: 0px 19px;
  border-radius: 40px;
  width: 100%;
  margin-top: 5px;
  height: 43px;
  border: 1px solid #a62274 !important;
  color: #000;
}

.text_field_robot {
  width: 60px !important;
  height: 35px !important;
  margin-top: -1px !important;
}

.slider-form-outer input.form-control,
.slider-form-outer select#you_are {
  border: 1px solid #000 !important;
}

@media only screen and (max-width: 320px) {
  .faq_anss {
    width: 220px;
    padding: 15px 29px 40px 29px;
  }
}
@media only screen and (max-width: 768px) {
  .faq_anss {
    width: 220px;
    padding: 15px 29px 40px 29px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .faqbutn {
    margin-bottom: 45px !important;
  }
  .faq_question{
    margin-left: 0px !important;
  }
  .videowidthh{
    width: 250px;
  }
  .learning_form_responsive{
    display: flex;
    justify-content: space-evenly;
  }
  .freedomestyle{
    /* width: 358px !important;
    top:90px !important */
  }
  .mobile_none{
    display: none !important;
  }
  .deliver_sec{
    margin-top: 15px;
  }
  .small_text{
    font-size: 80px !important;
  }
  .vision_div{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .home_banner_text{
    margin-top: 280px !important;
  }
  .text_res{
    font-size: 24px !important;
  }
  .margintop{
    margin-top: 20px ;
  }
  .margintopphone{
    margin-top: 16px;
  }
  .font{
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 375px) {
  .freedomestyle{
    width: 320px !important;
    top:90px !important
  }
  .request_tutor{
    background-image:none !important;
    background-color: #C12F89;
    padding: 20px !important;
  }
  .key_stage_banner{
    background-image: none !important;
  }
  .banner_sub_text{
    width: auto !important;
  }
  .black_bg{
    background-color: #000;
  }
  .detail_banner{
    background-color:#E8269C;
  }
  .key_stage_bannertext{
    margin-top: 0px !important;
  }
  .mobile_none{
    display: none !important;
  }
  .deliver_sec{
    margin-top: 15px;
  }
  .small_text{
    font-size: 80px !important;
  }
  .faq_anss{
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .faqbutn {
    margin-bottom: 45px !important;
  }
  .widthauto{
    width: auto !important;
  }
  .font{
    font-size: 30px !important;
  }
  .font20{
    font-size: 20px !important;
  }
.learning_form_responsive{
  display: flex;
  justify-content: space-evenly;
}
.fontsize{
  font-size: 44px !important;
}
.mobile_none{
  display: none;
}
.videowidthh{
    width: 250px;
}
.deliver_class{
  margin-top: 10px;
}
.margintopphone{
  margin-top: 16px;
}
.responsive{
  display: flex;
  justify-content: space-evenly !important;
}
.review_div {
  width: 350px !important;
}
.one_to_one {
  width: 350px !important;
}
.vision{
  width: 220px !important;
}
.reviewcoursep{
  padding: 10px;
}

.why_edu1 {
  margin-top: 11px !important;
}
.why_edu2 {
  margin-top: 0px !important;
  margin-left: 17px !important;
}
.sciencemb{
  margin-bottom: 10% !important;
}
.section_4text{
  width: 300px !important;
}
.section_4 {
  background-image: none !important;
}

.sub_text {
  width: 74%;
  font-size: 14px !important;
}
.worktodoline{
  line-height: 100%;
}
.how_it_work{
  background-image: url(../assets/img/about/how_it_work2.png) !important;
  background-size: cover;
  background-position: center; 
  height: 380px !important; 
}
.subtext_margin {
  margin-top: 25px !important; 
  margin-left: 134px !important;
}
.widthhwitwork{
  width: 73% !important;
}
.text_res{
  font-size: 24px !important;
}
.margintop{
  margin-top: 20px ;
}
.fontstyle{
  font-size: 30px !important;
}
}
@media only screen and (max-width: 414px) {
  .faq_anss{
    margin-left: auto !important;
    margin-right: auto !important;
    width: 270px;
  }
  .request_tutor{
    background-image:none !important;
    background-color: #C12F89;
    padding: 20px !important;
  }
  .key_stage_bannertext{
    margin-top: 0px !important;
  }
  .banner_sub_text{
    width: auto !important;
  }
  .black_bg{
    background-color: #000;
  }
  .key_stage_banner{
    background-image: none !important;
  }
  .detail_banner{
    background-color:#E8269C;
  }
  .faqbutn {
    margin-bottom: 45px !important;
  }
  #root{
    overflow: hidden;
  }
  .videowidthh{
    width: 300px;
}
.font{
  font-size: 30px !important;
}
.font20{
  font-size: 20px !important;
}
.widthauto{
  width: auto !important;
}
.vision_div{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.review_div {
  width: 295px !important;
}
.one_to_one {
  width: 295px !important;
}
.vision{
  width: auto !important;
}
.reviewcoursep{
  padding: 10px;
}

.why_edu1 {
  margin-top: 11px !important;
}
.why_edu2 {
  margin-top: 0px !important;
}
.sciencemb{
  margin-bottom: 10% !important;
}
.section_4text{
  width: 300px !important;
}
.section_4 {
  background-image: none !important;
}
.text_res{
  font-size: 24px !important;
}
.margintop{
  margin-top: 20px ;
}
.fontstyle{
  font-size: 30px !important;
}

.main_banner{
  background-image: none !important;
  background-color: #C7318D !important;
  padding-bottom: 20px;
}
}

@media only screen and (max-width: 280px) {
    .faq_anss {
      width: 200px;
      padding: 15px 29px 45px 29px;
    }
    .black_bg{
      background-color: #000;
    }
    .detail_banner{
      background-color:#E8269C;
    }
    .key_stage_bannertext{
      margin-top: 0px !important;
    }
    .key_stage_banner{
      background-image: none !important;
    }
    .videowidthh{
        width: 350px;
    }
    .review_div {
      width: 350px !important;
    }
    .one_to_one {
      width: 350px !important;
    }
    .vision{
      width: auto !important;
    }
    .reviewcoursep{
      padding: 10px;
    }
    .vision_div{
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .widthauto{
      width: auto !important;
    }
    .font20{
      font-size: 20px !important;
    }
    .request_tutor{
      background-image:none !important;
      background-color: #C12F89;
      padding: 20px !important;
    }
    .banner_sub_text{
      width: auto !important;
    }
    
.why_edu1 {
  margin-top: 11px !important;
}
.why_edu2 {
  margin-top: 0px !important;
}
.sciencemb{
  margin-bottom: 10% !important;
}
.section_4text{
  width: 300px !important;
}
.section_4 {
  background-image: none !important;
}
.text_res{
  font-size: 24px !important;
}
.fontstyle{
  font-size: 30px !important;
}
  }

  .request_tutor{
    background-image: url(../assets/img/request_tutor_banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 155px;
  }
  .banner_sub_text{
    color: rgb(255, 255, 255);
    text-align: justify;
    font-size: 20px;
  }
  .slider-form-outer input.form-control, .slider-form-outer select#you_are{
    border: 1px solid #000 !important;
  }

  .pink_bg{
    background-image: url(../assets/img/request_tutor.png);
    background-size: cover;
    margin-top: 500px !important;
    background-repeat: no-repeat;
  }
  .vision_div{
    margin-left: 15%;
    margin-right: 15%;
  }

  .text_res{
    font-size: 30px;
  }

.vision_div {
  margin-left: 15%;
  margin-right: 15%;
}

.text_res {
  font-size: 30px;
}

/* .table>:not(caption)>*>*{
  padding: 0.3rem 0.5rem !important; 
} */

.input { 
  width: 40px; 
  border: none; 
  border-bottom: 3px solid rgba(0, 0, 0, 0.5); 
  margin: 0 10px; 
  text-align: center; 
  font-size: 36px; 
  cursor: not-allowed; 
  pointer-events: none; 
} 

.input:focus { 
  border-bottom: 3px solid orange; 
  outline: none; 
} 

.input:nth-child(1) { 
  cursor: pointer; 
  pointer-events: all; 
}
.reviewCard{
  border-radius: 16px;
  width: 70%;
  box-shadow: 0px 0px 12px 0px #80808047;
  border: 2px solid #80808054;
  padding: 50px;
  margin-bottom: 50px;
  justify-content: space-evenly;
}
.select_input{
  height: 55px !important;
  border-radius: 30px !important;
  font-size: 19px !important;
  padding-left: 24px !important;
  color: gray !important;
}

#about img, #services img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

#about div, #services div {
  padding: 20px;
}
.faq_sec {
  background: #f3f3f3;
  padding: 40px;
  border-radius: 8px;
}

.largefont {
  font-size: 36px;
  margin-bottom: 20px;
}

.video-container {
  margin-top: 20px;
}

.video-caption {
  color: #666;
}
.deliver_class {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.deliver_class:hover {
  transform: translateY(-5px);
}

.sec_2_text {
  font-size: 20px;
  /* color: #333; */
  margin-top: 15px;
  font-weight: normal;
}
.service-page-new{
  background-position: inherit !important;
}
.one-to-one p{
  font-size: 16px;
  color: #000;
}
.one-to-one h3{
  font-size: 25px;
  margin-top: 50px;
}
