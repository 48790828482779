.contact_us_main{
    background-image: url(../assets/img/subjects/contact_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: space-evenly;
}

.contactradius{
    border: none !important;
    border-radius: 50px;
    font-size: 18px;
    padding-left: 25px;
    height: 55px;
}

.contact_head{
    text-align: justify;
    font-weight: 700;
    font-size: 40px;
    color: rgb(255, 255, 255);
}
.contact_sub{
    text-align: justify !important;
    font-weight: 600;
    font-size: 18px;
    color: rgb(255, 255, 255);
}
.react-tel-input .form-control{
    border: none !important;
}
.react-tel-input .flag-dropdown{
    border: none !important;
    background-color: #fff !important;
}