.pink-bg{
    background-image: url(../../assets/img/about/how_we_work.png);
    background-size: cover;
    background-position: bottom center;
    padding: 119px;
}

.head_Text{
    text-align: justify;
    font-weight: 700;
    font-size: 40px;
    color: rgb(255, 255, 255);
}
.how_it_work{
    background-image: url(../../assets/img/about/how_it_work.png);
    background-size: cover;
    padding-bottom: 80px;
}

.sub_text{
    text-align: justify !important;
    color: rgb(255, 255, 255);
    font-size: 21px;
}
.subtext_margin{
    margin-top: 124px;
}

.whats_include{
        background-image: url(../../assets/img/about/whats_include_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 40px;
}

.include_div{
    border: 1px solid #000;
    border-radius: 20px;
    width: 250px;
    height: 250px;
}
.imgcenter{
    display: flex;
    justify-content: space-evenly;
}
.include_sub_text{
    color: #000;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.main_banner{
    background-image: url(../../assets/img/about/our_approach.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    padding-top: 10px;
}

.mission_bg{
    border: none;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
}
.mission_div{
    background-color: #fff;
    border-radius: 25px;
    border: 2px solid #A72475;
    padding: 15px;
}
.youget_div{
    background-color: #fff;
    border-radius: 25px;
    border: 2px solid #A72475;
    padding: 25px;
}
.mission_heading{
    color: rgb(226, 60, 162);
    font-weight: 700;
    text-align: center;
}

.our_mission{
    background-image: url(../../assets/img/about/mission_bg.png);
    background-repeat: no-repeat;
    background-position: center;
}

.video_bg{
    background-image: url(../../assets/img/subjects/video_bg.png);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 169px;
}
.video_style{
    width: 450px !important;
}

.membership_banner{
        background-image: url(../../assets/img/membership_banner.png);
        background-size: cover;
        background-position: center;
        padding: 70px;
}
.member_head_text{
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 22px;
}

.price_text{
    color: #A32272;
    font-size: 55px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
}
.min_text{
    color: #E23CA2;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 15px !important;
}

.right_div{
    display: flex;
    justify-content: space-evenly;
}

.feature_text{
    color: #6E6E6E;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
}

.hover_white:hover{
    color: #971E69 !important;
}

.tab_div{
    border: 1px solid #000;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 30px;
    padding: 14px;
    margin: 5px;
    cursor: pointer;
}

.tab_div.active {
    background-color: #000;
}
.tab_text.active {
    color: #fff;
}
.tab_text{
    color: #000;
    font-size: 22px;
    font-weight: 600;
}

.block_font{
    text-align: justify;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

