.hii {
	position: relative !important;
	top: 30%;
	left: 0;
	z-index: 1;
	width: 100%;
	background-color: #ccc;
}
section.sptb.bg-white p span {
	font-family: var(--thm-font) !important;
	font-size: 16px;
	color: #000;
}


section.sptb.bg-white {
	box-shadow: 0px 0px 7px #000;
	border-radius: 10px;
	padding: 17px 6px !important;
}
.maths-banner-test {
	width: 520px;
	background-color: #fff;
	box-shadow: 0px 0px 10px #0000009e;
	border-radius: 50px 0 50px 0;
	padding: 30px 30px 30px;
	margin-top: -18px;
}
section#maths-page-section {
  background-image: url(../../assets/img/m-banner.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 440px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

section#science-page-section {
  background-image: url(../../assets/img/s-banner.jpg);
  width: 100%;
}

.science-banner-test {
  width: 520px;
}

section#computing-page-section {
  background-image: url(../../assets/img/c-banner.jpg);
  width: 100%;
}

section#english-page-section {
  background-image: url(../../assets/img/e-banner.jpg);
  width: 100%;
}

.english-banner-test {
  width: 640px;
}
.yearbutton{
    margin-top: 9%;
    display: flex;
    justify-content: space-evenly;
    height: fit-content;
}
.cardnewkey{
    width: 290px;
    background-color: #000 !important;
    padding: 16px;
    border-radius: 28px !important;
    margin-right: auto;
}
@media (max-width : 375px) {
	.cardnewkey{
		width: 290px;
		background-color: #000 !important;
		padding: 16px;
		border-radius: 28px !important;
		margin-right: auto;
		margin-left: auto;
		margin-top: 10px;
	}
	.responsive{
		justify-content: unset;
	}
}
.details-card-header{
	background-color: rgb(0, 0, 0);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
	padding: 15px 15px;
}
.details-card-body{
	padding: 10px;
	color: #000;
	min-height: 340px;
	box-shadow: 0px 2px 7px 0px gray;
	border: 1px solid #dee2e6 !important;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.format{
	display: flex;
}
.set{
	color: #A22070;
	margin-top: 5px;
}
.padding{
	padding: 0px 18px 18px 18px;
}
.custom-control{
	position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    -webkit-print-color-adjust: exact;
}
.react-loading-skeleton{
	--base-color:#000 !important
}
.colorChange{
	--base-color:#5C1951 !important
}
.grayColor{
	--base-color:#ACACAC !important
}
.modal{
	position: fixed;
    top: 0 ;
    left: 0 ;
    right: 0 ;
    /* bottom: 0 ; */
    margin-left: 50% ;
    margin-right: 50% ;
    margin-top: 24% !important;
	border: 5px solid #7a1353;
}
.quizheading{
	display: flex;
	justify-content: space-evenly;
}
.quiztext{
	font-size: 20px;
	color: #000;
}
.paddingmcq{
	padding-top:8px;
}
div.radio-with-Icon{
	display: block;
}
div.radio-with-Icon div.radioOption-Item{
	display: inline-block;
    width: 287px;
    height: auto;
    box-sizing: border-box;
    border: none;
}
.border{
	border-radius: 0px !important;
}
.paddingtop{
	padding-top: 17px !important;
}
div.radio-with-Icon div.radioOption-Item input[type="radio"]{
	opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
}
div.radio-with-Icon div.radioOption-Item label{
	display: block;
    height: 100%;
    width: 100%;
    padding: 17px;
    border-radius: 10px;
    border: 1px solid #89185E;
    color: #89185E;
    cursor: pointer;
    opacity: .8;
    transition: none;
    font-size: 13px;
    text-align: center;
    margin: 0 !important;
}
.nextbutton{
	position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
}
.checked {
	background-color: #89185E; /* Set your desired background color here */
	color: #fff !important;
}
.checked p{
	color: #fff !important;
}
.subimg{
	width: 200px !important;
}
.modal-body
{
	background: transparent;
	overflow-y: auto;
    max-height: 580px;
}
.modal-footer{
	display: block !important;
}
.success{
	color: green;
	font-weight: bold;
	font-size: 22px;
}
.danger{
	color: red;
	font-weight: bold;
	font-size: 22px;
}
.cross-sign{
	color: #fff;
    font-size: 20px;
    border: none;
    background-color: #000;
}
.modal-banner{
	height: 60px;
    display: flex;
    flex-wrap: nowrap;
    background-color: rgb(0, 0, 0);
	padding-top: 10px;
}
.modal-header{
	background-color: #000;
}
.equation {
	font-size: 18px;
	margin-right: 10px;
	white-space: nowrap;
  }
  .captcha-btn{
	margin-left: 8px;
    margin-top: -5px;
    width: 100px;
    font-size: 13px !important;
  }

  .head_font{
	color: #000;
	font-size: 48px;
	font-weight: 700;
  line-height: 46px;
  }

  .review_course{
	background-image:url(../../assets/img/subjects/review_bg.png);
	background-color: #fff;
	background-size: cover;
	background-repeat: no-repeat;
  }
  .review_div{
	background-color: #000;
	border-radius: 60px;
	width: 70%;
  margin-left: auto;
  margin-right: auto;
  }
  .key_font{
	font-size: 30px;
    font-weight: 700;
    color: #fff;
	margin-top: 60px;
  }
  .vertical-line {
	background-color: #fff;
    width: 1px;
    height: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 51px;
  }
  .marginbtn{
	padding-bottom: 30px !important;
  }

  .horizontal_line{
	margin-right: auto;
    margin-left: auto;
    width: 89%;
    color: #fff;
    height: 1px;
    background-color: #fff;
    opacity: 1;
  }

  .top{
	margin-top: -24px !important;
	height: 96% !important;
  }

  .one_to_one{
	color: rgb(0, 0, 0);
    font-weight: 500;
    text-align: justify !important;
    margin-left: auto;
    margin-right: auto;
    font-size: 23px;
  }

  .why_edu1{
    margin-top: -40px;
  }
  .enganing{
    margin-top: 30px;
  }

  .why_edu2{
   margin-left: 48px;
   margin-top: -20px;

  }
  .advance_child{
	text-align: justify !important;
    font-size: 22px;
    font-weight: 500;
  }

  .banner_radius{
	border-top-left-radius: 60px;
	border-top-right-radius: 60px;
  }

  .left{
	margin-left: 0px !important;
  }
  .right{
	margin-right: 0px !important;
  }

  .career_div{
	  border: 1px solid #E23CA2;
    border-radius: 20px;
	  padding: 45px;
    height: 400px;
    width: 450px;
  }
  
  .career_diveng{
    border: 1px solid #E23CA2;
    border-radius: 20px;
    padding: 30px;
    height: 510px;
    width: 530px; 
  }

  .career_text{
	margin-top: 20px;
    color: #E23CA2;
    font-weight: 600;
    font-size: 28px;
	text-align: justify !important;
	line-height: 32px;
  }

  .career_font{
	text-align: justify !important;
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }
  .bg-black{
	border: 1px solid #E23CA2;
  }

  .imagestyle{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .line_width{
    margin-top: 40px;
    width: 70%;
  }
  .new_bg{
    background-color: #fff;
    background-image: url(../../assets/img/subjects/english_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .center{
    text-align: center !important;
  }
  .section_4{
    background-image: url(../../assets/img/subjects/section_4bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;
  }

  .section_4text{
    text-align: justify !important;
    font-weight: 500;
    font-size: 23px;
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 112px;
    margin-bottom: 90px;
  }

  .sec4headtext{
    margin-left: 70px;
    font-weight: 500;
    font-size: 35px;
    color: rgb(169, 37, 118);
  }

  .headingbg{
    display: flex;
    border: 1px solid #000 !important;
    justify-content: space-evenly;
    align-items: center;
  }

  .key_stage_banner{
      background-image: url(../../assets/img/subjects/sub_banner.png);
      background-size: cover;
  }

  .key_stage_bannertext{
    text-align: justify !important;
    font-weight: 500;
    font-size: 37px;
    margin-top: 420px;
    color: rgb(255, 255, 255);
  }

  .que_ansbtn{
    height: 50px;
    padding-top: 0px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #000;
    width: 150px;
    font-size: 20px;
    margin: 10px;
  }
  .que_ansbtn:hover{
    color: #fff;
    background-color: #E23CA2;
    border: 1px solid #E23CA2;
  }

  .premium_btn{
    width: 50%;
    height: 100px;
    border: 1px solid #fff;
    border-radius: 50px;
    font-size: 30px;
  }
  .premium_btn:hover{
    width: 50%;
    height: 100px;
    border: 1px solid #fff;
    border-radius: 50px;
    font-size: 30px;
  }

  .past_exam{
    height: 50px !important;
    width: 150px !important;
    font-size: 22px !important;
  }
  .past_exam:hover{
    height: 50px !important;
    width: 150px !important;
    font-size: 22px !important;
    color: #fff;
    background-color: #E23CA2;
    border: 1px solid #E23CA2;
  }
  .quiztext{
    color: #000 ;
    font-weight: bold ;
    font-size: 20px ;
    text-align: center !important;
  }
  .punjabi_font{
    color: #000;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .choose_card{
    border-radius: 25px;
    border: 2px solid #E23CA2;
    background: #FFF;
    padding: 30px;
    width: 290px;
    height: 295px;
  }
  .card_head{
    color: #E23CA2;
    text-align: justify;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .card_sub_text{
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 138.523%;
    margin-top: 20px;
  }
  .curr_head{
    color: #FFF;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .curr_sub_text{
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.523%;
  }
  .vertical-line {
    border-left: 1px solid #9E9E9E; /* You can customize the color and thickness */
    height: 100%; /* Ensures the line takes the full height of the container */
  }
  .benifit_text{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .benifit_head{
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .benifit_sub{
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .curr_card{
    text-align: center;
    margin-bottom: 4rem;
  }