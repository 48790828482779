/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap"); */

:root {
    /* Colors */
    --brand-color: #8D1961;
    --black: hsl(0, 0%, 0%);
    --white: hsl(0, 0%, 100%);
    /* Fonts */
    --font-title: "Montserrat", sans-serif;
    --font-text: "Lato", sans-serif;
  }
  
  /* RESET */
  
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Remove default margin */
  body,
  .h22,
  p {
    margin: 0;
  }
  
  /* GLOBAL STYLES */
  /* body {
    display: grid;
    place-items: center;
    height: 100vh;
  } */
  
  .h22 {
    font-size: 2.25rem;
    font-family: var(--font-title);
    color: var(--white);
    line-height: 1.1;
  }
  
  .pragefg {
    font-family: var(--font-text);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--white);
  }
  
  .flow > * + * {
    margin-top: var(--flow-space, 1em);
  }
  
  /* CARD COMPONENT */
  
  .cardquiz {
      display: grid;
      place-items: center;
      width: 70vw;
      max-width: 17.875rem;
      height: 25.125rem;
      overflow: hidden;
      border-radius: 0.625rem;
      border-color: #255F51;
      box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
  
  .cardquiz > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .card__background {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
  
  .card__content {
    width: 266px;
    --flow-space: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    padding: 12% 1.25rem 1.875rem;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.3) 10%,
      hsl(0, 0%, 0%) 100%
    );
  }
  
  .card__content--container {
    --flow-space: 1.25rem;
  }
  
  .card__title {
    position: relative;
    width: fit-content;
    width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  }
  
  .card__title::after {
    content: "";
    position: absolute;
    height: 0.3125rem;
    width: calc(100% + 1.25rem);
    bottom: calc((1.25rem - 0.5rem) * -1);
    left: -1.25rem;
    background-color: var(--brand-color);
  }
  @media (any-hover: hover) and (any-pointer: fine) {
    .card__content {
      transform: translateY(62%);
      transition: transform 500ms ease-out;
      transition-delay: 500ms;
    }
  
    .card__title::after {
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 1000ms ease-in, transform 500ms ease-out;
      transition-delay: 500ms;
      transform-origin: right;
    }
  
    .card__background {
      transition: transform 500ms ease-in;
    }
  
    .card__content--container > :not(.card__title),
    .card__button {
      opacity: 0;
      transition: transform 500ms ease-out, opacity 500ms ease-out;
    }

    .card:hover .card__content,
    .card:focus-within .card__content {
      transform: translateY(0);
      transition: transform 500ms ease-in;
    }
  
    .card:focus-within .card__content {
      transition-duration: 0ms;
    }
  
    .card:hover .card__background,
    .card:focus-within .card__background {
      transform: scale(1.3);
    }
  
    .card:hover .card__content--container > :not(.card__title),
    .card:hover .card__button,
    .card:focus-within .card__content--container > :not(.card__title),
    .card:focus-within .card__button {
      opacity: 1;
      transition: opacity 500ms ease-in;
      transition-delay: 1000ms;
    }
  
    .card:hover .card__title::after,
    .card:focus-within .card__title::after {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: left;
      transition: opacity 500ms ease-in, transform 500ms ease-in;
      transition-delay: 500ms;
    }
  }
  
  .quizwidth{
    width: 55%;
  }
  
  @media only screen and (max-width: 600px) {
    .quizwidth{
      width: auto;
    }
  }