h3,
p:last-child {
    margin-bottom: 0
}

body,
p {
    color: var(--thm-p)
}

*,
dl,
li,
ol,
p,
ul {
    margin: 0;
    padding: 0
}

a,
a:hover,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}

#home,
.atf-single-details,
.stepwarp {
    z-index: 1
}

.card img,
img {
    max-width: 100%
}

#feature,
#home,
.stepwarp,
.wizard_mainwarp,
td.time-zone-india b {
    position: relative
}

#book-a-turorrr,
#loginnn,
#tutor-registrationnnnnn {
    padding: 40px 0 150px;
    margin-bottom: -57px;
    background-position: center
}

section#computing-page-section,
section#english-page-section,
section#science-page-section {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 440px
}

html {
    overflow-x: hidden;
    height: 100%
}

.edudelivers {
    margin-top: 65px !important;
    display: flex;
    flex-direction: column;
    align-items: center
}

body {
    font-family: var(--thm-font);
    font-weight: 400;
    font-style: normal;
    font-size: 14px
}

:root {
    --thm-font: 'Open Sans', sans-serif;
    --thm-font2: 'Bubblegum Sans', sans-serif;
    --thm-base: #fb9512;
    --thm-line: linear-gradient(to bottom, #FF416C, #fb9512);
    --thm-black: #5a1e90;
    --thm-white: #ffffff;
    --thm-ase: #afb2bf;
    --thm-gray: #8e8e8e;
    --thm-f6: #F1F8FF;
    --thm-fb: #f6f6f6;
    --thm-f3: #f3f3f3;
    --thm-p: #6b6f92;
    --thm-color: #5c1951
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--thm-font2);
    color: #000
}

.atf-section-title h5,
p {
    font-family: var(--thm-font)
}

h2 {
    font-size: 39px ;
    line-height: 50px !important;
    margin-bottom: 0 !important;
    font-weight: 400 !important
}

.atf-section-title p,
h3,
p {
    font-weight: 400
}

h3 {
    font-size: 22px;
    line-height: 32px
}

h4 {
    font-size: 20px;
    line-height: 27px
}

.conrt.new h3,
h5 {
    line-height: 26px
}

@media only screen and (max-width:600px) {
    .lending-btn {
        margin-bottom: 15px
    }

    .lastslider {
        margin-top: 20px !important
    }

    .loginbutton {
        margin-left: 0 !important
    }
    .marginTop{
        margin-top: 16px;
    }
}

.loginbutton {
    margin-left: 57px
}

h5 {
    font-size: 18px
}

h6,
p {
    font-size: 14px
}

h6 {
    line-height: 22px
}

p {
    line-height: 29px;
}

img {
    height: auto;
    border: 0
}

a {
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.btn:focus,
a:active,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0
}

*,
::after,
::before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

span {
    letter-spacing: 0
}

a,
button,
input {
    outline: 0;
    color: inherit
}

dl,
ol,
ul {
    list-style: none
}

button:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: transparent !important
}

::-webkit-input-placeholder {
    color: inherit
}

::-moz-input-placeholder {
    color: inherit
}

::-ms-input-placeholder {
    color: inherit
}

.form-group {
    margin-bottom: 10px
}

button,
input,
textarea {
    color: var(--thm-black)
}

button,
input,
textarea:focus {
    outline: 0
}

.atf-color-ase {
    color: var(--thm-ase) !important
}

.atf-section-title,
.atf-section-title.text-center.Up.contacttext,
section#membershipp ul.list-unstyled {
    margin-bottom: 20px
}

.atf-section-title h5 {
    color: var(--thm-white);
    line-height: 25px;
    margin: 0 0 5px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 14px;
    background: #cc2e90;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px
}

.atf-section-title h2 {
    font-weight: 400;
    font-size: 34px;
    line-height: 1.3;
    color: var(--thm-black);
    text-transform: capitalize;
    font-family: var(--thm-font2);
    text-align: center
}

.atf-section-title p {
    font-size: 14px;
    font-family: var(--thm-font);
    color: var(--thm-p);
    line-height: 28px;
    margin-top: 8px
}

@media only screen and (max-width:480px) {
    .atf-section-title h2 {
        font-size: 25px
    }
}

@media only screen and (max-width:767px) {

    .stepwarp,
    .stepwarp.table_resp a.back_btn.btn {
        width: 100%
    }

    .stepwarp.table_resp,
    .tb_responsive {
        float: none !important
    }

    .tb_responsive {
        backdrop-filter: inherit;
        overflow: auto;
        margin-bottom: 20px
    }

    .availability-table.responsdtable {
        width: 801px;
        position: relative;
        overflow: auto;
        margin: 0;
        padding: 0
    }

    .stepwarp {
        float: left !important
    }

    .stepwarp.table_resp a.btn.btn-next {
        width: 100%;
        margin-top: 10px;
        float: none
    }

    .stepwarp.table_resp .custom-control {
        padding-left: 2.1rem
    }

    .responsdtable thead {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit
    }

    .card-body.bg-white.mt-0.shadow.bgggggg {
        margin-bottom: 30px
    }

    section#membershipp h2 {
        font-size: 33px
    }

    .navbar-nav .menu-nav a::after,
    .navbar-nav .menu-nav a::before {
        display: none
    }

    .atf-skill-box {
        margin-top: 35px
    }
}

#feature {
    background-color: #f1f8ff !important
}

section#feature p {
    text-align: justify
}

#class {
    background: #fff !important
}

.btn-success {
    border: 1px solid #59fb86;
    background-color: #59fb86;
    color: #000 !important
}

.atf-content-details .btn {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding: 12px 15px;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: .3s;
    border: none;
    margin: 5px;
    min-width: 150px
}

.btn-primary {
    color: #fff;
    background-color: #d329e2;
    border-color: #d329e2
}

.btn-secondary {
    color: #fff;
    background-color: #998cc6;
    border-color: #6c757d
}

.btn-info {
    color: #fff;
    background-color: #50bee1;
    border-color: #50bee1
}

.input-link i {
    margin-right: 5px
}

.stepwarp .input-link a {
    font-weight: 600;
    text-transform: capitalize;
    color: rgb(90 30 144 / 85%)
}

.wizard_mainwarp {
    max-width: 60%;
    border-radius: 5px;
    overflow: hidden;
    padding: 0
}

.wizard_mainwarp:before {
    content: '';
    background-image: url('http://test.networkalgo.com/designer/educationcapsule_new/assets/img/32484.jpg');
    background-size: 100%;
    opacity: .7;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

h3.step_title {
    padding: 0 0 5px;
    font-size: 20px;
    color: #000
}

.stepwarp .custom-control.custom-radio {
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 12px 15px 14px 40px;
    background-color: #fff
}

.stepwarp .input-link {
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 12px 15px
}

.stepwarp {
    background-color: rgb(255 255 255 / 92%);
    padding: 25px
}

.stepwarp .custom-control-label {
    width: 100%;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;
    cursor: pointer
}

.availability-table thead tr td,
.stepwarp .btn {
    text-transform: uppercase
}

.app h4,
.black,
.blue b,
.form-group.has-feedback h4,
.green b,
.red b,
.registration-form-outer h2.register-heading,
.scroll p,
.student-feedback-form-outer h4,
.terms-condation .card-body ul li,
section#exam-help-section h1,
table.availability-table.responsdtable td {
    color: #000
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #fb9512;
    background-color: #fb9512
}

.edit-profile,
.stepwarp .btn-next {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    color: #fff;
    float: right
}

.pinkcolor {
    color: #a1467e !important
}

.stepwarp .btn {
    min-width: 120px;
    border-radius: 4px;
    margin: 0
}

.back_btn.btn {
    background-color: #9186ba;
    color: #fff
}

.stepwarp .form-control {
    height: 45px;
    font-size: 14px;
    color: #000;
    font-weight: 600
}

p.step_subtitle {
    font-size: 15px;
    font-weight: 500;
    margin: 8px 0
}

table.availability-table tr td {
    border: 1px solid #ddd;
    padding: 14px;
    font-size: 14px;
    font-weight: 600
}

table.availability-table {
    width: 100%;
    margin-bottom: 25px;
    border-collapse: collapse
}

.availability-table .custom-checkbox .custom-control-input,
.availability-table .custom-checkbox .custom-control-label::after,
.availability-table .custom-checkbox .custom-control-label::before {
    width: 2rem;
    height: 2rem
}

.availability-table .text-light {
    color: #7b7b7b !important;
    display: block
}

.custom-checkbox .custom-control-label::before {
    border-radius: .1rem
}

.custom-control-label::before {
    top: .15rem;
    background-color: #fff;
    border: 1px solid #adadad
}

.form-group.forgot_warp {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px
}

.btn.btn-fb {
    background-color: #3b5999 !important;
    border-color: #3b5999 !important;
    color: #fff
}

.custom-control-label::after {
    top: .2rem
}

.atf-section-padding {
    padding: 74px 0
}

a.profile_pic img {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 50%
}
.padding50{
    margin:  20px 20px 20px 100px;
}

.padding20{
    padding:  20px ;
}

.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    margin: 10px 0;
    border: 2px solid #000000a6 !important;
    padding: 8px;
    border-radius: 28px
}

.card:hover {
    box-shadow: 0 0 12px #cc2e8f
}

.card-header {
    padding: 15px;
    margin-bottom: 0;
    background-color: #5c1951;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header .card-title {
    color: #fff;
    margin: 0;
    font-size: 22px;
    text-transform: capitalize
}

@media (max-width :992px) {
    .card-body {
        padding: 12px 3px !important;
        z-index: 111;
        min-height: 175px
    }

    .card-body p {
        line-height: 22px
    }
}

@media (max-width :767px) {
    .atf-hero-btn.mt-5 {
        text-align: center;
        margin-top: 28px !important
    }

    .card.m-5 {
        margin: 0 !important
    }

    .main-content {
        position: absolute;
        top: 20%;
        left: 15px;
        z-index: 1;
        width: 96%;
        right: 15px
    }

    .container,
    .container-sm {
        max-width: 100%
    }

    .cariculam-help-section {
        width: 100% !important;
        text-align: center;
        margin: 0 auto
    }

    .exam-bannner h1,
    .text-center.Up h2,
    section#examhelp-sec-2 h2,
    section#examhelp-sec-4 h2 {
        font-size: 30px !important
    }

    .cariculam-help-section h2 {
        font-size: 30px !important;
        line-height: 36px !important
    }

    section#exam-help-section {
        padding: 0 !important;
        text-align: center
    }

    section#examhelp-sec-5 h2 {
        font-size: 30px;
        margin-bottom: 0
    }

    .outer,
    .testimonial_section .about_content .background_layer,
    section#exam-help-section {
        width: 100% !important
    }

    .homework-data {
        text-align: left;
        padding: 30px 15px 0 !important
    }

    .outer .icon-outer-2 img {
        margin-top: 0 !important
    }

    .atf-footer-area::before {
        border-radius: 0
    }

    .testimonial_section {
        display: block;
        overflow: visible !important
    }
}

@media (max-width :480px) {
    .stepwarp .btn {
        WIDTH: 100%;
        margin-bottom: 12px
    }
}

.tutor-page button.btn.btn-primary.dropdown-toggle {
    background-color: var(--thm-black);
    border: 1px solid var(--thm-black)
}

li.nav-item.my-booking a,
li.nav-item.progress-report a,
li.nav-item.start-session a {
    font-family: var(--thm-font2);
    font-size: 22px;
    padding: 18px 51px;
    border-radius: 0 24px;
    margin-bottom: 20px;
    border: none;
    margin-left: 20px;
    letter-spacing: 1px
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc
}

.tutor-page .dropdown {
    float: right;
    margin-bottom: 40px
}

.tutor-page button.btn.btn-primary.dropdown-toggle {
    background-color: #5c1951;
    border: 1px solid #5c1951
}

.tutor-page button.btn.btn-primary.dropdown-toggle:hover {
    background-color: #fb9512;
    border-color: #fb9512
}

ul.dropdown-menu.show li {
    line-height: 28px;
    border-bottom: 1px dashed #000
}

ul.dropdown-menu.show li a:hover {
    color: #fb9512
}

.dropdown-menu.show {
    padding: 8px 15px 17px;
    font-size: 14px
}

.tutor-page.students-page .dropdown {
    float: right;
    margin-bottom: 30px
}

.tabbing-content-outer ul.nav.nav-tabs li a {
    font-weight: 700;
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    color: #fff
}

.nav-tabs {
    border-bottom: none !important
}

.tabbing-content-outer ul li {
    padding: 2px;
    text-align: center
}

.tabbing-content-outer a.nav-link.active {
    background: #000 !important;
    color: #fff !important;
    border: none !important
}

.dropdown.tutorrrr button.btn.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: 2px dashed #000;
    border-radius: 20px 0;
    padding: 50px 100px;
    width: 100% !important;
    color: #000;
    font-weight: 700;
    display: block
}

.dropdown.tutorrrr.show {
    border: 2px dashed #000;
    border-radius: 20px 0
}

.dropdown.tutorrrr.show ul.dropdown-menu.show {
    width: 100%;
    margin-top: -40px
}

.dropdown.tutorrrr.show .tabbing-content-outer ul li {
    padding: 2px;
    text-align: left
}

.dropdown.tutorrrr ul.dropdown-menu.show {
    background-color: transparent;
    color: #fff;
    padding: 0 20px;
    border: none
}

.dropdown.tutorrrr ul.dropdown-menu.show li {
    background-color: var(--thm-black);
    line-height: 44px;
    text-align: left;
    background-color: #5c1951;
    color: #fff;
    margin-bottom: 4px;
    border: 3px dashed #000;
    padding: 0 12px;
    font-weight: 700;
    border-radius: 16px 0
}

.dropdown.tutorrrr ul.dropdown-menu.show li:hover {
    background-color: #ffc107 !important;
    color: #000
}

.rate label {
    font-size: 44px !important
}

.rate:not(:checked)>label:before {
    content: '★ '
}

.rate>input:checked~label {
    color: #ffc700
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #deb217
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #c59b08
}

.rating-text-area textarea#w3review {
    padding: 10px 15px 15px 13px;
    color: #000;
    margin-top: 10px
}

td.time-zone-india b {
    top: -5px;
    right: 3px
}

.tutor-page.student-feedback {
    height: 100%
}

.student-feedback-form-outer {
    background-color: #fff;
    padding: 50px 50px 20px;
    border-radius: 30px;
    box-shadow: 2px 1px 22px #8d8d8d;
    width: 690px;
    margin: 0 auto
}

.student-feedback-form-outer button.btn.btn-primary,
.tutor-feedback-form button.btn.btn-primary {
    border: none;
    padding: 12px 36px;
    text-transform: uppercase;
    background-color: #5c1951;
    font-weight: 600
}

.item,
.thank-img-2 span img {
    width: 90px
}

.student-feedback-form-outer h4 {
    font-size: 31px;
    margin-bottom: 26px
}

.student-feedback-form-outer .container {
    display: flex;
    display: flex;
    width: 479px;
    margin-bottom: 30px;
    border-bottom: 3px dashed #ccc
}

.student-feedback-form-outer .rate {
    float: none;
    height: 46px;
    padding: 0 10px;
    margin: 0 auto;
    text-align: center;
    display: inline-block
}

.student-feedback-page h1 {
    color: #fff;
    margin-bottom: 18px
}

.student-feedback-form-outer .rating-text-area textarea#w3review {
    padding: 10px 15px 15px 13px;
    color: #000;
    margin-top: 30px
}

.student-feedback-form-outer button.btn.btn-primary {
    margin: 10px 0 30px
}

.item {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none
}

.carousel-control-next,
.carousel-control-prev,
.radio,
body#bookfreedemo header.atf-site-header.atf-style1.atf-sticky-header,
section#book-a-turorrr br {
    display: none
}

.radio~span {
    font-size: 3rem;
    cursor: pointer;
    transition: .3s
}

.radio:checked~span {
    filter: grayscale(0);
    font-size: 4rem
}

.student-feedback-form-outer.tutor-feedback-form .container {
    display: flex;
    width: 479px;
    margin-bottom: 30px;
    border-bottom: 3px dashed #ccc;
    padding-bottom: 20px
}

.student-feedback-form-outer.tutor-feedback-form .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
    width: 18px !important;
    height: 18px !important
}

.tutor-feedback-form .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
    margin-right: 20px
}

.tutor-feedback-form .form-check label.form-check-label {
    margin-left: 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 26px
}

.tutor-feedback-form textarea {
    margin-top: 10px !important
}

.tutor-feedback-form button.btn.btn-primary {
    margin: 0 0 30px
}

.btn.btn-primary.dashboard-btnnnnn,
.card-body.border ul li,
.how-works h5 {
    text-transform: capitalize
}

.tutor-feedback-form .checkbox-outer {
    width: 253px;
    display: flex;
    margin: 10px auto 0
}

.how-works .circle {
    font-weight: 700;
    padding: 15px 20px;
    border-radius: 50%;
    background-color: #5c1951;
    color: #fff;
    max-height: 50px;
    z-index: 2
}

.how-works h5 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .2px;
    font-family: 'Open Sans'
}

.how-works .timeline .col-2 {
    display: flex;
    overflow: hidden
}

.how-works .how-it-works.row,
ul.list-unstyled.widget-spec.p-1 li a {
    display: flex
}

.how-works .how-it-works.row .col-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center
}

.how-works .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%
}

.how-works .timeline .top-right {
    left: 50%;
    top: -50%
}

.how-works .timeline .corner {
    border: 3px solid #5c1951;
    width: 100%;
    position: relative;
    border-radius: 15px
}

.how-works .timeline div {
    padding: 0;
    height: 40px
}

.how-works .timeline hr {
    border-top: 3px solid #5c1951;
    margin: 0;
    top: 17px;
    position: relative
}

.how-works .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px)
}

.how-works .timeline .top-left {
    left: -50%;
    top: -50%
}

.how-works .timeline .right-bottom {
    left: 50%;
    top: calc(50% - 3px)
}

section.how-works {
    background-color: #fcfcff
}

.App {
    text-align: left !important
}

.how-works .how-it-works.row .col-2 img {
    width: 250px
}

section#examhelp-sec-6 .homework-data-4,
section.how-works.atf-section-padding {
    background-color: #fff
}

.rating-text-area p {
    width: 100%;
    float: left;
    margin-bottom: 14px
}

.rate i {
    color: #ffc107;
    font-size: 20px;
    margin-top: 6px;
    margin-left: 3px
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1210px !important
    }

    .english-banner-test {
        width: 400px
    }

    .col-xl-8 {
        max-width: 100% !important
    }
}

@media (max-width :991px) {

    .atf-nav .atf-nav-list a:hover,
    .atf-nav .atf-nav-list>li>a {
        background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%)
    }

    .atf-nav .atf-nav-list>li>a {
        color: #fff
    }

    .form-control {
        height: 39px;
        font-size: 13px !important
    }

    h2.register-heading {
        font-size: 30px !important
    }

    .register-left img {
        margin-top: 100% !important
    }

    .hello {
        width: 99% !important;
        height: 20%;
        position: absolute;
        top: 570px !important;
        transform: skewY(-10deg);
        background: #fff
    }

    .atf-section-title,
    .atf-section-title h5 {
        margin-bottom: 20px
    }

    .atf-section-padding {
        padding: 60px 0
    }

    #footer {
        position: relative;
        display: inline-block;
        overflow: hidden
    }
}

@media (max-width :767px) {
    .atf-hero-btn.mt-5 {
        text-align: center;
        margin-top: 28px !important
    }

    .card.m-5 {
        margin: 0 !important
    }

    .main-content {
        position: absolute;
        top: 20%;
        left: 15px;
        z-index: 1;
        width: 96%;
        right: 15px
    }

    .container,
    .container-sm {
        max-width: 100%
    }

    iframe {
        width: 100%;
        height: 206px
    }

    .wizard_mainwarp {
        max-width: 90%;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        padding: 0;
        margin-left: 0
    }

    h1.atf-single-details.text-center.text-light {
        font-size: 31px
    }

    .atf-section-title.text-center.wow.fadeInUp h1 {
        font-size: 30px
    }

    .student-feedback-form-outer {
        width: 100%;
        padding: 30px 30px 20px
    }

    textarea#w3review {
        width: 100%
    }

    section#examhelp-sec-4 .icon-outer-2 img {
        display: none
    }

    section#exam-help-section {
        width: 100%;
        float: left;
        padding: 0 !important
    }
    .marginTop{
        margin-top: 16px;
    }
}

ul.atf-top-header-list li a {
    font-weight: 600
}

.text-bgg-new {
    border-radius: 30% 70% 59% 41%/30% 38% 62% 70%;
    height: 185px;
    border: 1px dashed
}

textarea#message {
    border: 1px solid #dfdfdf;
    font-size: 15px
}

.pl-lg-5,
.px-lg-5 {
    padding-left: 1rem !important
}

section#exam-help-section {
    padding: 80px 0;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    background-color: #f1f8ff
}

.register .nav-tabs .nav-link:hover,
section#exam-help-section a {
    border: none
}

section#examhelp-sec-2 {
    text-align: center;
    padding: 60px 0
}

section#examhelp-sec-4,
section#examhelp-sec-5 {
    text-align: center;
    padding: 60px 0 0
}

section#examhelp-sec-2 h2 {
    color: #000;
    margin-bottom: 15px
}

section#examhelp-sec-2 p {
    font-size: 14px;
    text-align: justify
}

section#examhelp-sec-4 h2,
section#examhelp-sec-5 h2 {
    color: #000;
    margin-bottom: 25px
}

.icon-outer-2 img {
    margin-top: -30px
}

.cariculam-help-section {
    width: 800px;
    text-align: center;
    margin: 0 auto
}

.homework-data {
    text-align: left;
    padding: 70px 30px 30px
}

.homework-data h3 {
    font-size: 27px;
    color: #000;
    margin-bottom: 12px
}

.outer {
    width: 1000px;
    margin: 0 auto
}

section#examhelp-sec-5 .homework-data {
    text-align: left;
    padding: 25px;
    background-color: #f1f8ff;
    border: 2px dashed #a32e90;
    border-radius: 20px
}

.homework-data-4 {
    text-align: left;
    margin-top: 70px;
    font-weight: 700;
    line-height: 38px;
    border: 2px dashed #a32e90;
    padding: 30px;
    background-color: #f1f8ff;
    border-radius: 20px;
    color: #000
}

.center-class,
.register-heading,
.register-left,
.thank-img img,
.thank-img-2 img,
.thank-img-2 p,
section#examhelp-sec-6 h2 {
    text-align: center
}

.outer.testi h4 {
    color: #000;
    margin-top: 8px
}

.outer.testi .homework-data-4 {
    background-color: #fff;
    margin-top: 30px;
    min-height: 184px
}

section#examhelp-sec-6 {
    background-color: #f1f8ff;
    padding: 60px 0 0
}

section#examhelp-sec-6 h2 {
    color: #000
}

section#examhelp-sec-6 .homework-data {
    text-align: left;
    padding: 50px;
    background-color: #fff;
    margin-top: 20px;
    border: 2px dashed #a32e90;
    border-radius: 50px 0
}

a.btn.btn-info.student-registration {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    padding: 12px 35px;
    font-weight: 600;
    margin-top: 10px;
    border: none !important
}

.start-quiz a:hover,
a.btn.btn-info.student-registration:hover,
section#membershipp .btn-custom:hover {
    background: linear-gradient(to bottom, #7a1353 0, #cc2e8f 100%)
}

.homework-data.app {
    padding-top: 15px
}

.texxt-centerrr {
    padding: 34px 30px 0 0
}

.right-img img {
    border: 1px solid #ccc;
    padding: 6px;
    background-color: #fff;
    box-shadow: 0 0 6px #000
}

footer#footer {
    display: block !important;
    margin-bottom: -21px !important
}

.underline {
    text-decoration: underline !important
}

#book-a-turorrr,
#loginnn {
    position: relative;
    background-size: cover
}

#tutor-registrationnnnnn {
    position: relative;
    background-size: cover
}

#tutor-registrationnnnnn h1,
section#book-a-turorrr h1 {
    text-shadow: 1px 2px 2px #000;
    animation: 1s infinite alternate mover;
    background-color: #00000096;
    padding: 10px;
    width: 290px;
    margin: 0 auto;
    border-radius: 5px
}

section#book-a-turorrr a,
section#book-a-turorrr a:hover {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    margin-top: 7px
}

.main-content {
    position: relative !important
}

.main-content h1 {
    margin-top: 10PX;
    color: #fff !important
}

.register,
section#loginnn .container.registration {
    background: #000000c7;
    margin-top: 3%;
    padding: 3%
}

.register-left {
    color: #fff;
    margin-top: 4%
}

.register-left input {
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: 700;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer
}

.register-right {
    background: #f8f9fa;
    margin-right: auto;
    margin-left: auto;
    width: 95% !important
}

.register-left img {
    margin-top: 55%;
    margin-bottom: 5%;
    width: 92%;
    -webkit-animation: 2s infinite alternate mover;
    animation: 1s infinite alternate mover;
    background-color: #000;
    padding: 19px;
    border-radius: 15px;
    box-shadow: 0 0 25px #fff
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-20px)
    }
}

@keyframes mover {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-20px)
    }
}

.register-left p {
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%
}

.register .register-form {
    padding: 10%
}

.category-card {
    color: #fff !important;
    height: 100px;
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%) !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%) !important;
    border: none !important;
    margin-top: 100px !important;
    padding: 8px !important;
    border-radius: 28px !important;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-around;
    align-items: center;
    display: flex !important
}

.btnRegister,
form#studentRegistrationForm button#submit {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    color: #fff
}

.text,
.text-align {
    line-height: .6;
    margin-left: 60px
}

.tutor-register-form {
    padding-right: 10%;
    padding-top: 22%;
    padding-left: 10%
}

.tutor-register-form-subject {
    padding-right: 10%;
    padding-top: 10%;
    padding-left: 10%
}

.tutor-register-form-terms {
    padding-right: 5%;
    padding-top: 22%;
    padding-left: 5%
}

.category-card:hover {
    box-shadow: 0 0 12px #cc2e8f !important;
    color: #fff
}

.btnRegister {
    float: right;
    margin-top: 12px;
    border: none;
    border-radius: 1.5rem;
    padding: 12px;
    font-weight: 600;
    width: 50%;
    cursor: pointer
}

.backbutton,
.next_button {
    color: #fff !important;
    width: 150px;
    height: 45px;
    display: flex !important;
    float: right
}

.next_button {
    align-items: center;
    justify-content: space-around;
    margin-right: 10%;
    margin-top: 44px !important;
    margin-bottom: 5%
}

.text {
    margin-top: 30px
}

.how_we_work_class {
    visibility: visible;
    animation-duration: 1s;
    animation-delay: .3s
}

.btn:hover {
    color: #fff !important
}

.backbutton {
    justify-content: space-around;
    align-items: center;
    margin-right: 10px;
    margin-top: 44px !important
}

.height {
    height: 48px !important
}

.register .nav-tabs {
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right
}

.form-control,
.register-form .form-control {
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: 2px 2px 0 #a62274;
    height: 45px;
    color: #000;
    font-weight: 400;
    font-size: 14px
}

.form-control,
.register-form input.btnRegister {
    width: 100%;
    display: block
}

.register .nav-tabs .nav-link {
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
}

.register .nav-tabs .nav-link.active {
    width: 100px;
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
}

section#book-a-turorrr h1.register-heading.contact-heading {
    background-color: transparent;
    font-weight: 400 !important;
    animation: none !important;
    letter-spacing: 1.2px;
    box-shadow: none !important;
    text-align: center;
    margin-top: 7%;
    margin-bottom: -17%;
    color: #000;
    text-shadow: none
}

section#book-a-turorrr .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 20px
}

.register-heading {
    margin-top: 8%;
    margin-bottom: -15%;
    color: #000
}

.form-control {
    padding: .375rem .75rem;
    border-radius: .25rem;
    border: 1px solid #000 !important
}

form#studentRegistrationForm button#submit {
    margin-top: 7px
}

section#thank-you {
    padding: 80px 0;
    background: url(../img/bac-bggg.png)
}

.thank-img img {
    animation: 1s infinite alternate mover;
    width: 210px;
    margin: 0 auto;
    display: block
}

.thank-img-2 img {
    padding: 22px 0;
    margin: 0 auto;
    display: block
}

.thank-img-2 p {
    font-size: 20px;
    font-weight: 700;
    color: #000
}

.form-group.forgottt {
    padding: 16px 0 0
}

section#loginnn .container.register {
    width: 80%
}

.register-form .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    border-radius: .25rem;
    border: 1px solid #000 !important;
    margin-bottom: 7px
}

.slider-form-outer input.form-control,
.slider-form-outer select#you_are {
    margin-bottom: 10px;
    box-shadow: none
}

.carousel-indicators {
    top: 94%
}

ol,
ul {
    padding-left: 0 !important
}

.carousel-indicators li {
    background-color: #ba2882 !important
}

.carousel-indicators [data-bs-target] {
    background-color: #a72274 !important
}

section {
    padding: 100px 0
}

.hello {
    width: 96%;
    height: 20%;
    position: absolute;
    top: 165px;
    transform: skewY(-10deg);
    background: #fff
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 20px !important;
    z-index: 111
}

.card-body h5 {
    font-size: 20px;
    color: #000;
    margin-bottom: 4px;
    text-align: s
}

.slider-image img {
    border-radius: 5%;
    padding: 13px !important;
    box-shadow: 0 0 12px #ccc;
    background-color: #fff
}

.slider-form-outer .btnRegister,
.start-quiz a,
section#membershipp .btn-custom {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%)
}

.slider-form-outer .btnRegister {
    float: none;
    margin-top: 15px !important;
    border: none;
    border-radius: 1.5rem;
    padding: 12px;
    color: #fff;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    display: block
}

a.btnRegister {
    color: #fff;
    width: 100%;
    text-align: center
}

.btnRegister:hover {
    color: #fff;
    text-decoration: none
}

.card-body.border ul li a p {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin-left: 0 !important;
    line-height: 20px
}

section#membershipp {
    background-color: #f1f8ff;
    margin-bottom: -62px;
    padding: 70px 0 130px
}

section#membershipp h2 {
    text-align: center;
    background-color: #000c;
    width: 320px;
    margin: 0 auto 30px;
    color: #fff;
    padding: 10px 0;
    border-radius: 6px
}

.pricing-divider {
    border-radius: 20px;
    background: #c64545;
    padding: 1em 0 4em;
    position: relative
}

.blue .pricing-divider {
    background: #2d5772
}

.green .pricing-divider {
    background: #1aa85c
}

.pricing-divider-img {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 80px
}

section#membershipp .btn-custom {
    color: #fff;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 700
}

.princing-item {
    transition: 150ms ease-out
}

.princing-item:hover {
    transform: scale(1.05)
}

.card-body.border ul li a p span {
    color: #000;
    font-size: 12px !important
}

.start-quiz {
    padding: 9px 0 15px;
    border-radius: 8px;
    margin: 0 auto;
    text-align: center
}

.start-quiz h2 {
    color: #000;
    margin-bottom: 20px;
    font-size: 32px
}

.start-quiz a {
    padding: 10px 32px;
    color: #fff;
    border-radius: 0 14px;
    font-weight: 600;
    margin: 5px
}

.testimonial_section .about_content .layer_content .section_title:after,
.testimonial_section:after {
    display: block;
    clear: both;
    content: ""
}

.testimonial_section .about_content {
    padding-top: 77px;
    padding-right: 210px;
    padding-bottom: 62px;
    position: relative;
    border-radius: 20px
}

.testimonial_section .about_content .background_layer {
    margin-left: -200px;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0
}

.background_layer.newwwwwwwwwww {
    background-color: #0d9992 !important
}

.testimonial_section .about_content .layer_content {
    position: relative;
    z-index: 9;
    height: 100%
}

.testimonial_section .about_content .layer_content .section_title {
    margin-bottom: 24px;
    position: relative
}

.testimonial_section .about_content .layer_content .section_title h5 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #818a8f;
    margin-top: -5px;
    margin-bottom: 6px
}

.testimonial_section .about_content .layer_content .section_title h2 {
    font-weight: 300;
    font-size: 42px;
    line-height: 50px;
    padding-bottom: 51px;
    margin-bottom: 0;
    color: #b0257a
}

.conrt.new h3 {
    color: #000 !important;
    font-size: 24px
}

.testimonial_section .about_content .layer_content .section_title h2 strong {
    font-weight: 600 !important;
    width: 100%;
    display: block
}

.testimonial_section .about_content .layer_content .section_title .heading_line {
    position: relative
}

.testimonial_section .about_content .layer_content .section_title .heading_line span {
    transition: .5s ease-in-out;
    position: relative
}

.testimonial_section .about_content .layer_content .section_title .heading_line span:after {
    content: "";
    right: 145px;
    position: absolute;
    bottom: 28px;
    width: 17px;
    margin-left: 0;
    border-bottom: 3px solid #cacaca
}

.testimonial_section .about_content .layer_content .section_title .heading_line:after {
    content: "";
    left: 1%;
    margin-left: 0;
    position: absolute;
    bottom: 28px;
    width: 59px;
    border-bottom: 3px solid #cc2e8f
}

section.testimonial_section a {
    padding: 14px 30px;
    border-radius: 19px
}

section.testimonial_section a:hover {
    background-color: #cc2e8f;
    color: #fff !important
}

.testimonial_section .about_content .layer_content .section_title p {
    color: #000;
    margin: 0 0 14px;
    font-size: 18px;
    display: inline-block;
    line-height: 30px
}

.testimonial_section .about_content .layer_content a {
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    transition: .3s;
    background-color: #cc2e8f
}

.testimonial_section .about_content .layer_content a i {
    font-size: 18px;
    vertical-align: middle
}

.testimonial_section .about_content .layer_content a:hover {
    color: #ff5e14
}

.testimonial_section .testimonial_box {
    margin-top: 60px !important;
    position: relative
}

.testimonial_section .testimonial_box .testimonial_container {
    margin-left: -170px !important;
    position: relative;
    border-radius: 20px
}

.testimonial_section .testimonial_box .testimonial_container .background_layer {
    width: auto;
    margin-right: -200px;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px
}

.testimonial_section .testimonial_box .testimonial_container .layer_content {
    position: relative;
    z-index: 9
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel {
    display: block;
    position: relative
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials {
    margin: 10px 0;
    padding: 62px 0 72px 50px;
    position: relative;
    text-align: center
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .13);
    margin-left: 150px;
    margin-top: 69px;
    padding: 45px 40px;
    position: relative;
    background-color: #fff;
    transition: .5s ease-in-out
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content .testimonial_caption {
    margin-bottom: 15px;
    position: relative
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content .testimonial_caption:after {
    content: "";
    width: 30px;
    display: block;
    height: 2px;
    text-align: center;
    left: 46%;
    margin-top: 6px;
    background-color: #ff5e14;
    position: absolute
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content .testimonial_caption h6 {
    padding-top: 0;
    margin-bottom: -5px;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    color: #020d26
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content .testimonial_caption span {
    font-size: 12px;
    color: #9f9f9f;
    margin: 0
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .testimonial_content p {
    padding: 10px 0 0;
    margin: 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #5d6576;
    font-style: italic
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .images_box .testimonial_img {
    border: none;
    position: absolute;
    left: 55px;
    top: 80px
}

.testimonial_section .testimonial_box .testimonial_container .layer_content .testimonial_owlCarousel .testimonials .images_box .testimonial_img img {
    border: 5px solid #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    width: 35%
}

.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-next,
.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-prev {
    top: 165px;
    border-radius: 0;
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    outline: 0;
    width: 34px;
    height: 34px;
    font-size: 23px;
    margin-top: -20px;
    transition: .3s ease-in-out;
    line-height: 34px;
    display: block;
    position: absolute;
    color: #fff
}

.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-prev {
    right: 42px
}

.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-next:hover,
.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-prev:hover {
    background: #020d26
}

.testimonial_section .testimonial_box .testimonial_container .owl-nav .owl-next {
    right: 5px;
    text-align: center
}

@media all and (max-width:991px) {
    .testimonial_section .about_content {
        padding-right: 15px !important
    }

    .testimonial_section .about_content .background_layer {
        width: 200% !important
    }

    .testimonial_section .testimonial_box {
        margin-top: 0 !important
    }

    .testimonial_section .testimonial_box .background_layer {
        width: 200% !important;
        margin-left: -200px
    }

    .testimonial_section .testimonial_box .about_content {
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-top: 28% !important
    }

    .testimonial_section .testimonial_box .testimonial_container {
        margin-left: -15px !important
    }

    .testimonial_section .testimonial_box .testimonial_container .testimonials {
        margin: 0 0 20px
    }

    .testimonial_section .testimonial_box .testimonial_container .testimonials .testimonial_content {
        margin-left: -36px !important
    }

    .testimonial_section .testimonial_box .testimonial_container .testimonials .images_box {
        display: none
    }
    .marginTop{
        margin-top: 16px;
    }
}

.privacy-policy,
.terms-condation {
    padding: 0
}

.privacy-policy .card-header,
.terms-condation .card-header {
    padding: 15px;
    margin-bottom: 0;
    background: #000;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    text-align: center
}

.privacy-policy p,
.terms-condation .card-body p {
    margin-bottom: 14px;
    color: #000
}

.how-works img,
section#computing-page-section,
section#english-page-section,
section#landing-footer,
section#science-page-section {
    margin: 0 auto;
    text-align: center
}

.how-we-work-page,
.session-outer-heading,
.text-bggg-new-2 {
    text-align: left
}

.how-we-work-page-2 ul li {
    color: #fff;
    font-weight: 600;
    line-height: 33px;
    border-bottom: 1px dashed;
    text-align: left;
    font-size: 15px
}

.how-we-work-page h3 {
    font-size: 17px;
    line-height: 34px;
    padding-right: 30px;
    font-family: 'Open Sans';
    font-weight: 600
}

.how-we-work-page-2 {
    border: 1px solid #ccc;
    padding: 30px;
    background-color: #000;
    border-radius: 19px
}

.how-we-work-page-2 h4 {
    color: #f71aa2;
    font-size: 24px
}

.how-works img {
    width: 100px;
    display: block
}

.text-bggg-new h5,
.text-bggg-new-2 h5 {
    margin-right: 20px;
    letter-spacing: 0;
    font-weight: 700;
    color: #000
}

.how-works {
    margin: 20px 0;
    text-align: center
}

.how-works h3 {
    margin-top: 11px
}

.how-we-work-page img {
    background-color: #fff;
    padding: 9px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc
}

.text-bggg-new h5,
.text-bggg-new-2 h5 {
    background-color: transparent;
    text-align: left
}

section#membershipp ul.list-unstyled li {
    text-align: left;
    line-height: 38px;
    border-bottom: 1px dashed #a4a3a3;
    font-weight: 600;
    color: #000;
    font-size: 13px
}

section#membershipp .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 20px 20px !important;
    z-index: 111
}

.btn.btn-primary.dashboard-btnnnnn {
    padding: 16px 34px;
    border-radius: 0 24px;
    margin-top: 0;
    background-color: #000;
    color: #fff;
    border: none;
    font-size: 13px;
    font-weight: 600
}

.card-body.border ul li a p span span {
    color: #000;
    font-size: 14px;
    font-family: var(--thm-font) !important
}

.card-body.border ul li a {
    font-weight: 600;
    font-family: var(--thm-font) !important;
    font-size: 12px
}

.card-body.bg-white.mt-0.shadow.bgggggg ul li i.fa.fa-check {
    color: green;
    margin-right: 8px
}

.card-body.bg-white.mt-0.shadow.bgggggg ul li i.fa.fa-times {
    color: red;
    margin-right: 8px
}

section#membershipp ul.list-unstyled li i {
    float: right;
    position: relative;
    top: 14px
}

.btn.btn-info.btn-lg.model {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%) !important;
    border-radius: 13px 0;
    border: none;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600
}

.computing-banner-test,
.english-banner-test,
.science-banner-test {
    background-color: #fff;
    box-shadow: 0 0 10px #0000009e;
    border-radius: 50px 0;
    padding: 30px;
    margin-top: -54px
}

.close {
    position: absolute;
    top: 12px;
    right: 16px
}

[type=radio]+img {
    cursor: pointer
}

[type=radio]:checked+img {
    outline: #f00 solid 2px
}

label img {
    padding: 10px
}

label.heading-lable {
    font-weight: 700
}

.app.upload-resource h4 {
    margin-right: 17px
}

.computing-banner-test {
    width: 580px
}

.session-outer-heading-2 {
    text-align: right
}

.session-outer-heading h4,
.session-outer-heading-2 h4 {
    margin-bottom: 7px !important;
    font-size: 21px
}

.row.session-report-new {
    margin-bottom: 38px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px
}

.text-bggg-new {
    text-align: left;
    display: flex !important;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 5px 18px;
    border: 1px solid #ccc;
    background-color: #f3f3f3
}

.text-bggg-new h5 {
    padding: 23px 0 19px;
    font-size: 16px;
    width: 344px
}

.text-bggg-new .fa.fa-star.checked {
    color: #fdbe0c;
    font-size: 21px;
    margin-top: 15px;
    position: relative
}

.text-bggg-new-2 b {
    color: #000;
    font-size: 18px;
    line-height: 8px
}

.text-bggg-new-2 h5 {
    padding: 0;
    font-size: 16px
}

.page_404 img,
.student-feedback-form-outer.newww {
    width: 100%
}

.text-bggg-new .item {
    width: 70px !important;
    height: 70px !important
}

.page.page-h.errorr-pagee img {
    width: 400px;
    margin: 50px 0 30px
}

.page.page-h.errorr-pagee p {
    font-size: 20px;
    color: #fff
}

.page.page-h.errorr-pagee a {
    background-color: transparent;
    text-transform: uppercase;
    border: 2px solid #fff;
    padding: 14px 40px;
    border-radius: 40px;
    margin-top: 15px
}

.page.page-h.errorr-pagee a:hover {
    background-color: #961c67
}

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: Arvo, serif
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center
}

.four_zero_four_bg h1,
.four_zero_four_bg h3 {
    font-size: 80px
}

.link_404 {
    color: #fff !important;
    padding: 20px 50px;
    background: #5c1951;
    margin: 20px 0;
    display: inline-block;
    font-family: var(--thm-font);
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 50px
}

.header-style,
.profile_card {
    margin-right: 5px;
    margin-left: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px
}

.link_404:hover {
    background: #000
}

.content-bg-landing ul li {
    font-size: 17px;
    font-weight: 600;
    line-height: 31px;
    color: #000
}

.content-bg-landing h3 {
    font-weight: 400;
    font-size: 30px;
    margin: 20px 0;
    color: #2e2e2e
}

.testimonial_section.nwww {
    display: block;
    overflow: hidden;
    padding: 0 0 70px
}

.atf-section-title.text-center.Up.leandingggg h2 {
    font-size: 39px;
    margin-bottom: 37px
}

hr.line {
    margin: 0;
    border-color: #1c223a;
    border-width: 1px;
    width: 4.5rem
}

hr.line.line-sm {
    width: 8.6rem
}

hr.line.bw-2 {
    border-width: 2px
}

.mb-5 {
    margin-bottom: .5rem !important
}

.mb-80 {
    margin-bottom: 8rem !important
}

.mb-20 {
    margin-bottom: 2rem !important
}

.mb-25 {
    margin-bottom: 2.5rem !important
}

.error {
    color: #e9f011 !important;
    position: relative !important;
    top: -6px !important
}

.testimonial_section-home {
    display: block;
    overflow: hidden;
    padding: 30px 0
}

.testimonial_section {
    display: block;
    overflow: hidden;
    padding: 0 0 40px;
    background-position: center top;
    margin-bottom: -59px;
    background-image: url(../img/about/testimonialbg.png);
    background-size: cover;
    background-repeat: no-repeat
}

body#bookfreedemo footer#footer {
    display: none !important
}

.list-items-bookfree-demo ul li a {
    padding: 0 20px;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 67px
}

.list-items-bookfree-demo ul {
    list-style: none;
    display: flex;
    float: right
}

.logo-img img {
    width: 153px
}

.row.landing-header {
    margin-bottom: 25px
}

.book-box img {
    width: 80px;
    margin: 0 38px
}

.row.social-icons {
    width: 780px;
    margin: 40px auto 0
}

section#landing-footer h3 {
    font-size: 55px;
    color: #fff;
    width: 100%;
    margin-top: 28px;
    margin-bottom: 33px
}

section#landing-footer {
    width: 100%
}

section#landing-footer p {
    color: #fff;
    font-size: 19px;
    line-height: 30px
}

.profile_card {
    height: 140px;
    background-color: #941c66;
    margin-top: 5px
}

.btn-edit-subject,
.view-resume {
    background: linear-gradient(to bottom, #cc2e8f 0, #7a1353 100%);
    color: #fff
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #941c66;
    margin-left: 5px;
    margin-right: 5px
}

.display {
    display: block
}

.enrolldemo {
    padding: 0 !important;
    width: 100% !important;
    height: 45px;
    margin-top: 0 !important
}

.scroll {
    padding: 4px;
    width: 730px;
    height: 304px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify
}

.btn-edit-subject {
    width: 100px
}

.header-style {
    background-color: #4a0e33;
    width: 490px;
    margin-top: 5px
}

.closeButton:hover {
    border-color: #7a1353 !important
}