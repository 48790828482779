.blog-list{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.blog-card {
    width: auto;  /* Set card width */
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;  /* Prevent content overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px;
    padding: 20px;
    background-color:black;
  }
  
  .blog-image {
    width: 100%;  /* Make image responsive */
    height: 200px; 
    object-fit: cover; 
    border-radius: 20px; /* Ensure the image fits well */
  }
  
  .blog-card h2, 
  .blog-card h3 {
    margin: 10px 0;
    color: white;
  }
  
  .blog-card p {
    font-size: 14px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
    color: white;
  }
  


  .truncate-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  
  